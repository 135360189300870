import React, { Component } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/molecules/SEO';
import Pagination from '../components/organisms/Pagination';
import ProductPreview from '../components/molecules/ProductPreview';
import PreHead from '../components/molecules/PreHead';
import ProductListTableHeader from '../components/molecules/ProductListTableHeader';
import ProductArchiveLayout from '../components/organisms/ProductArchiveLayout';


class ProductArchive extends Component {

  render() {
    const { productHeroImage } = this.props.data.contentfulGlobalElements;

    return (
      <>
        <SEO title="Products"/>
        <PreHead text="Products" fluidImage={productHeroImage.fluid}/>
        <ProductArchiveLayout>
            <ProductListTableHeader/>
            {this.props.data.allContentfulProduct.nodes.map( node => (
              <ProductPreview key={node.id} product={node} vendors={this.props.data.allContentfulVendor}/>
            ) )}
            <Pagination base="products" pageContext={this.props.pageContext}/>
        </ProductArchiveLayout>
      </>
    )
  }
}

export default ProductArchive;

export const productArchiveQuery = graphql`
    query productArchiveQuery($skip: Int!, $limit: Int!) {
        allContentfulProduct(
            sort: {fields: [title], order: ASC}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                id
                title
                slug
                excerpt {
                    excerpt
                }
            }
        }
        allContentfulVendor {
            nodes {
                id
                title
                logo {
                    fluid(maxWidth: 1000) {
                        src
                        srcSet
                        sizes
                        aspectRatio
                    }
                }
                slug
                products {
                    id
                }
            }
        }
        contentfulGlobalElements(title: {eq: "Global elements"}) {
            productHeroImage {
                fluid(maxWidth: 3000) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                }
            }
        }
    }
`;
